* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

input:focus,
textarea:focus {
  outline: none;
}

input::placeholder {
  color: rgba(154, 154, 154, 1);
}

ul {
  list-style: none;
}

button {
  /* border: none; */
  cursor: pointer;
}

a {
  text-decoration: none;
}

.react-datepicker {
  border: none;
}

.react-datepicker__header {
  background-color: #f0f0f043;
  bottom: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: rgba(0, 123, 255, 0.138);
  color: rgba(0, 122, 255, 1);
  border-radius: 100%;
  font-weight: 600;
}

.react-datepicker__day--keyboard-selected {
  background-color: #1976d2;
  color: white;
}

.react-datepicker__day:hover {
  border-radius: 100%;
  background-color: rgba(0, 123, 255, 0.138);
}

.react-datepicker-popper .react-datepicker__triangle {
  stroke: rgba(0, 122, 255, 1);
}

.react-datepicker__day--today {
  font-weight: bold;
  color: rgba(0, 122, 255, 1);
}

.ql-container {
  background: white;
  padding: 20px;
}

.ql-toolbar {
  background-color: #EDEDED;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
